jQuery(document).ready(function() {
  if (document.getElementById("users-datatable")) {
    $('#users-datatable').dataTable({
      "pageLength": 20,
      "dom": 'Bfrtip',
      "buttons": [
        {
            "text": 'Add User'
        }
      ],
      "columnDefs": [
        { "orderable": false, "targets": 0 },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": false, "targets": 0 },
        { "orderable": true }
      ]
    });

    var element = $('.add-user-btn').detach()
    $('.dt-buttons').append(element)
    $('.dt-button').remove()
    $('#users-datatable').removeClass('dataTable')
    
    $("tr[data-link]").click(function() {
      window.location = $(this).data("link")
    })

    $('input[type="search"]').addClass("searchbar")
  }
});

