import "expose-loader?exposes=$,jQuery!jquery"
import { Turbo } from "@hotwired/turbo-rails"
import Rails from "@rails/ujs"

require("../views/user")
require("../views/scripts.bundle")

import * as bootstrap from "../views/bootstrap"

Rails.start();
window.bootstrap = bootstrap;
Turbo.session.drive = false